<script setup>
import PageTitleCard from '@/components/PageTitleCard.vue';
import useLps from '@/hooks/useLps';
import { useUserStore } from '@/stores/user';
import { setPageTitle } from '@/utils/pageTitle';
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

const route = useRoute();
const lp = ref(null)
const palletTransactions = ref(null)
const userStore = useUserStore();
const showMoveForm = ref(false);
const newTxnVisible = ref(false);
const newTxnNotes = ref('');
const newTxnQty = ref(0);
const newBinId = ref('');
const history = ref([
  {
    id: 1,
    name: 'Pallet 1',
    created_at: new Date().toLocaleString(),
    body: 'Pallet 1 created'
  },
  {
    id: 2,
    name: 'Pallet 2',
    created_at: new Date().toLocaleString(),
    body: 'Pallet 2 created'
  },
  {
    id: 4,
    name: 'Pallet 1',
    created_at: new Date().toLocaleString(),
    body: 'Pallet 1 created'
  },
  {
    id: 5,
    name: 'Pallet 2',
    created_at: new Date().toLocaleString(),
    body: 'Pallet 2 created'
  },
])

const { fetchLp, fetchLpItemTransactions, voidLpTransaction } = useLps();
onMounted(async () => {
  lp.value = await fetchLp(route.params.id, {expand: 'warehouse'});
  palletTransactions.value = await fetchLpItemTransactions(route.params.id, { expand: 'created_by'});
  setPageTitle(`Pallets / ${lp.value.number}`);
})

const cancelMove = () => {
  showMoveForm.value = false;
  newBinId.value = '';
}

const voidTxn = async(txnId) => {
  const response = await voidLpTransaction(route.params.id, txnId);
}

</script>

<template>
  <PageTitleCard :pageTitle="pageTitle" >
    <template #actions>
      <div class="d-flex-end ml-auto mr-2">
        <!-- <% if @lp.quantity_mismatch?  && current_user.can?(:sync_lp_quantity) && current_user.lm_tenant?  %> -->
        <v-btn v-if="lp?.quantity_mismatch && userStore.user.permissions?.sync_lp_quantity" size="small" variant="outlined" class="font-weight-bold mr-2" color="primary">
          Sync Qty With Txs
        </v-btn>
        <!-- TODO Add PDF -->
        <v-btn size="small" variant="outlined" class="font-weight-bold" color="primary">
          Open PDF label
        </v-btn>
      </div>
    </template>
  </PageTitleCard>

  <div class="d-flex pa-4 ga-4 h-100">
    <div class="d-flex flex-column flex-grow-1 ga-4">
      <v-card class="w-100">
        <v-card-title class="bg-surface-light">Pallet Contents</v-card-title>
        <v-table>
          <thead>
            <tr>
              <th>ID</th>
              <th>SKU</th>
              <th>Product</th>
              <th>Lot</th>
              <th>Exp</th>
              <th>Qty</th>
            </tr>
          </thead>
          <tbody>
              <tr>
                <td>1</td>
                <td>
                  <a :href="`/products/${lp?.product.id}`">{{ lp?.product.sku }}</a>
                </td>
                <td>{{lp?.product.nickname}}</td>
                <td>{{lp?.lot}}</td>
                <td>{{lp?.expiry}}</td>
                <td>{{lp?.pallet_qty}}</td>
              </tr>
          </tbody>
        </v-table>
      </v-card>

      <v-card class="w-100">
        <v-card-title class="bg-surface-light">Pallet Transactions</v-card-title>
        <v-table class="border-b">
          <thead>
            <tr>
              <th>Time</th>
              <th>SKU</th>
              <th>By</th>
              <th>Lot</th>
              <th>Exp</th>
              <th>Txn Qty</th>
              <th>Order</th>
              <th>Notes</th>
              <th v-if="userStore.user.is_manager"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="palletTransactions?.length > 0" v-for="palletTx in palletTransactions" :key="palletTx.id">
              <td>{{ new Date(palletTx.created_at).toLocaleString("en-US") }}</td>
              <td>
                <a :href="`/products/${palletTx.lp?.product.id}`">{{palletTx.lp?.product.sku}}</a>
              </td>
              <td>{{palletTx.created_by.name}}</td>
              <td>{{palletTx.lp.lot}}</td>
              <td>{{palletTx.lp.expiry}}</td>
              <td>{{palletTx.qty}}</td>
              <td>
                <a
                  v-if="palletTx.order_type == 'Models::InboundOrder'"
                  :href="`/inbound-orders/${palletTx.order.id}`"
                  >{{ palletTx.order.number }}</a
                >
                <a
                  v-else-if="palletTx.order_type == 'Models::OutboundOrder'"
                  :href="`/outbound-orders/${palletTx.order.id}`"
                  >{{ palletTx.order.number }}</a
                >
              </td>
              <td><small>{{palletTx.notes}}</small></td>
              <td v-if="userStore.user.permissions.adjust_lp">
                <v-btn @click="voidTxn(palletTx.id)" size="x-small"variant="outlined" color="red">
                  VOID
                </v-btn>
              </td>
            </tr>
            <tr v-else>
              <td :colspan="userStore.user.is_manager ? 9 : 8" class="text-center">No Transactions</td>
            </tr>
          </tbody>
        </v-table>

        <!-- TODO: Add transaction -->
        <template v-if="userStore.user.tenant_id == 2">
          <v-card-text class="w-100 pa-4" v-if="newTxnVisible">
            <v-text-field
              label="Transaction Qty"
              class="mx-4"
              density="compact"
              hint="This the qty you want to add or subtract from this pallet. To remove qty, use a negative amount"
              persistent-hint
              type="number"
              :min="lp.pallet_qty * -1"
              step="1"
              v-model="newTxnQty">
            </v-text-field>
            <div class="mt-2">
              <v-textarea
                class="mx-4"
                density="compact"
                label="Notes"
                v-model="newTxnNotes">
              </v-textarea>
            </div>
            <div>
              <v-btn
                class="mx-4"
                color="primary"
                :disabled="!newTxnNotes || !newTxnQty">Process</v-btn>
              <v-btn
                class="mr-3"
                color="red"
                variant="outlined"
                @click="newTxnVisible = false">Cancel</v-btn>
            </div>
          </v-card-text>
          <div v-else class="d-flex justify-center">
            <v-btn
              class="ma-4"
              color="primary"
              variant="outlined"
              @click="newTxnVisible = true">Add Transaction</v-btn>
          </div>
        </template>
      </v-card>

      <!-- TODO API OPEN TRACTION -->
      <v-card class="w-100">
        <v-card-title class="bg-surface-light">Open Pallet Reservations</v-card-title>
        <v-table>
          <thead>
            <tr>
              <th>Time</th>
              <th>SKU</th>
              <th>Qty</th>
              <th>Order ID</th>
              <th>Picker</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1</td>
              <td>
                <a href="`/products`">Products</a>
              </td>
              <td>qty</td>
              <td>order id</td>
              <td>picker</td>
              <td>
                <v-btn size="x-small"variant="outlined" color="red">
                  Cancel
                </v-btn>
              </td>
            </tr>
          </tbody>
        </v-table>
      </v-card>
    </div>

    <v-card class="w-25 h-max-screen overflow-y-auto">
      <!-- <% if current_user.can?(:sync_lp_quantity) && current_user.original_tenant_id == Constants::Tenant::LM  %> -->
      <template v-if="userStore.user.permissions?.sync_lp_quantity">
        <v-card-title class="bg-surface-light">Tags</v-card-title>
        <div class="px-4 py-3">
          <span v-if="lp?.hidden || lp?.deleted || lp?.duplicate || lp?.quantity_mismatch || lp?.missing_inbound_tx">    
            <v-chip size="x-small" variant="flat" v-if="lp?.hidden" class="mr-1">HIDDEN</v-chip>
            <v-chip color="red" size="x-small" variant="flat" v-if="lp?.deleted" class="mr-1">DELETED</v-chip>
            <v-chip color="secondary" size="x-small" variant="flat" v-if="lp?.duplicate" class="mr-1">DUPLICATE</v-chip>
            <v-chip color="orange" size="x-small" variant="flat" v-if="lp?.quantity_mismatch" class="mr-1">QTY MISMATCH</v-chip>
            <!-- This needs to be checked with nolan -->
            <v-chip color="red" size="x-small" variant="flat" v-if="lp?.missing_inbound_tx" class="mr-1">MISSING INBOUND TX</v-chip>
          </span>

          <div v-else class="text-success text-sm text-center w-100">👍 No tags, LP looks good.</div>
        </div>
      </template>

      <v-card-title class="bg-surface-light">Location</v-card-title>
      <div class="px-4 py-3">
        <strong>Current Location</strong><br />
        <a class="text-decoration-none" v-if="lp?.bin" :href="`/bins/${lp.bin.id}`">{{lp?.warehouse?.nickname}} / {{lp?.bin?.name}}</a>
        <div class="mt-2">
          <v-btn
            v-if="!showMoveForm"
            size="x-small"
            color="primary" 
            class="ml-1" 
            variant="outlined"
            @click="showMoveForm = true"
            >Move
          </v-btn>
        </div>
      </div>
      <div v-if="showMoveForm" class="pa-4 bg-surface-light border-b">
        <strong>Move To: </strong>
        <v-autocomplete
          v-model="newBinId"
          class="pt-2"
          label="New Location"
          item-value="id"
          item-text="label"
          density="compact"
          variant="underlined"
        >
        </v-autocomplete>
        <v-btn size="small" color="primary" :disabled="!newBinId"
          >Process Move</v-btn>
        <v-btn
          size="small"
          color="red" 
          class="ml-1" 
          variant="outlined"
          @click="cancelMove"
          >Cancel
        </v-btn>
      </div>

      <v-card-title class="bg-surface-light">History</v-card-title>
      <v-table>
        <tbody>
          <tr v-if="history?.length > 0" v-for="hist in history" :key="hist.id">
            <td class="border-b py-2">
              {{hist.name}}<br />
              {{hist.created_at}}
            </td>
            <td class="border-b py-2">{{hist.body}}</td>
          </tr>
          <tr v-else>
            <td class="text-center">No history for this LP</td>
          </tr>
        </tbody>
      </v-table>

      <v-card variant="tonal" class="ma-4">
        <v-card-text>
          <strong>ID: </strong> {{lp?.id}}<br />
          <strong>PID: </strong> {{lp?.product.id}}<br />
          <strong>Lot:</strong> {{lp?.lot }}<br />
          <strong>Expiry: </strong> {{lp?.expiry}}<br />
          <strong>Qty: </strong>{{lp?.pallet_qty}}<br />
        </v-card-text>
      </v-card>
    </v-card>
  </div>
</template>

<style scoped>
.h-max-screen {
  max-height: 100vh;
}
</style>